/*
 * @Author: qiufh@bocspace.cn
 * @Date: 2020-07-10 07:42:03
 * @LastEditTime: 2020-11-10 10:22:11
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: /official_website/src/pages/products.js
 */ 
import React, { useEffect, useState } from 'react'
import url from 'url'

import Layout from '../components/layout'

const ProductServicePage = () => {
    const [typeFlag, setTypeFlag] = useState('camp');

    useEffect(()=>{
        let search = window.location.href
		const type = url.parse(search, true).query.type;
        console.log('type', type)
        setTypeFlag(type);
    }, [typeof window !== 'undefined' ? window.location.href : ''])

    return (
        <Layout>
            {
                typeFlag == 'camp' ? 
                <div>
                    <div className="bocsapce-introduce" >
                        <img className="content-img" src="/product/1.png" />
                    </div>
                    <div className="product-content" >
                        <div className="product-main-title" >
                            CAMP
                        </div>
                        <div className="product-sub-title" >
                            (Energy/Mining/Engineering Construction)
                        </div>
                        <div className="product-main-h2" >
                            Product Advantage
                        </div>
                        <div className="product-sub-h2" >
                            In some special industries such as mining and oil, 
                            the site construction environment is relatively poor, 
                            and affected by construction characteristics, 
                            there has been such a demand.  
                            The house can be used directly after it arrives at the site without 
                            installation and can be moved to another place as a whole with the progress of the project.
                        </div>
                        <div className="product-box" >
                            <div className="product-left" >
                                <div className="product-h3" >
                                    Fast
                                </div>
                                <div className="product-text" >
                                    After the design and production is completed, 
                                    the products can be transported to the construction site as a whole or packaged. 
                                    The assembly is fast and the construction period is greatly shortened compared 
                                    with traditional buildings of the same size. 
                                    The installation of a 3*6m frame only takes 15 minutes.
                                    1620㎡  2000 unit, Mobile Cabin Hospital Project in Xi'an Branch Port, 3 days.
                                </div>
                                <div className="product-h3" >
                                    Economical
                                </div>
                                <div className="product-text" >
                                    Products are produced on the assembly line in the factory to avoid uncontrolled 
                                    quality caused by different levels of workers. High production efficiency, 
                                    reduced production costs, less field operations greatly reduce the labor cost of field construction, 
                                    and can reduce the turnover of the whole life cycle of the project investment
                                </div>
                                <div className="product-h3" >
                                    Recyclable
                                </div>
                                <div className="product-text" >
                                    High degree of industrialization and high quality. 
                                    For short construction period of the project, 
                                    it has convenient turnover of other projects and reduces the cost of the whole life cycle.
                                </div>
                            </div>
                            <div className="product-right" >
                                <img className="content-img" src="/product/2.png" />
                            </div>
                        </div>
                    </div>
                    <div className="bocsapce-introduce" >
                        <img className="content-img" src="/product/3.png" />
                    </div>
                    <div className="bocsapce-introduce" >
                        <img className="content-img" src="/product/4.png" />
                    </div>
                    <div className="bocsapce-introduce" >
                        <img className="content-img" src="/product/5.png" />
                    </div>
                    <div className="bocsapce-introduce" >
                        <img className="content-img" src="/product/6.png" />
                    </div>
                    <div className="bocsapce-introduce" >
                        <img className="content-img" src="/product/7.png" />
                    </div>
                </div> : 
                typeFlag == 'china' ? 
                <div>
                    <div className="bocsapce-introduce" >
                        <img className="content-img" src="/product/1.png" />
                    </div>
                    <div className="product-content" >
                        <div className="product-title" >
                            CHINA-AIDED PROJECT 
                        </div>
                        <div className="product-h2" >
                            Dormitory for overseas hospitals, schools.  Rapid mass installation is accessible. 
                        </div>
                        <div className="product-box" >
                            <div className="product-left" >
                                <div className="product-h3" >
                                    Reliable supply capacity
                                </div>
                                <div className="product-text" >
                                    Our own Bocspace cloud factory has 6 production lines, 
                                    which produces 100 sets of packaging boxes every day, 
                                    with annual output of 30,000 sets and composite wall panel 1,000,000 ㎡.  
                                    At the same time, there are stable and reliable ecological 
                                    enterprises to ensure the strength of delivery.
                                </div>
                                <div className="product-h3" >
                                    Fast installation on site
                                </div>
                                <div className="product-text" >
                                    After the design and production is completed, 
                                    the products can be transported to the construction on-site as a 3D modular 
                                    unit or 2D panel model. The assembly is fast and the construction period 
                                    is greatly shortened compared with traditional buildings of the same size. 
                                    The installation of a 3*6m frame only takes 15 minutes. 
                                    1620㎡  2000 unit, Mobile Cabin Hospital Project in Xi'an Branch Port, 3 days
                                </div>
                                <div className="product-h3" >
                                    Quality stability
                                </div>
                                <div className="product-text" >
                                    Products are produced on the assembly line in the factory using robot welding, 
                                    to avoid the quality of workers caused by different levels of uncontrollable, 
                                    combined with Panasonic product control standards, high production efficiency 
                                    and good quality control. 
                                </div>
                            </div>
                            <div className="product-right" >
                                <img className="content-img" src="/product/8.png" />
                            </div>
                        </div>
                    </div>
                </div> : 
                typeFlag == 'relief' ? 
                <div>
                    <div className="bocsapce-introduce" >
                        <img className="content-img" src="/product/1.png" />
                    </div>
                    <div className="product-content" >
                        <div className="product-title" >
                            RESIDENTIAL
                        </div>
                        <div className="product-h2" >
                            Full living space, temporary residence, ADU
                        </div>
                        <div className="product-box" >
                            <div className="product-left" >
                                <div className="product-h3" >
                                    Private Customized 1~3 story single family home
                                </div>
                                <div className="product-text" >
                                    Customize your home with professional design according to your family's demand.
                                </div>
                                <div className="product-h3" >
                                    Intelligent movable rooms experience
                                </div>
                                <div className="product-text" >
                                    The partition wall in the room can be moved, 
                                    and the space can be changed according to the function. The two living rooms 
                                    in the big living room or the three rooms in the small living room can be easily 
                                    switched and their function can be changed.  
                                    It can be equipped with intelligent home system, 
                                    linkage intelligent devices to control the temperature, 
                                    humidity and light in the room, making living more comfortable.
                                </div>
                                <div className="product-h3" >
                                    Rapid construction, semi-permanent recyclable structural system
                                </div>
                                <div className="product-text" >
                                    The structure is made of light steel structure, 
                                    which is more industrialized and prefabricated than traditional concrete construction. 
                                    The site can be built faster and the structural system can be recycled and more 
                                    environmentally friendly. 
                                </div>
                            </div>
                            <div className="product-right" >
                                <img className="content-img" src="/product/9.png" />
                            </div>
                        </div>
                    </div>
                </div> : 
                typeFlag == 'house' ? 
                <div>
                    <div className="bocsapce-introduce" >
                        <img className="content-img" src="/product/1.png" />
                    </div>
                    <div className="product-content" >
                        <div className="product-title" >
                            EMERGENCY 
                        </div>
                        <div className="product-h2" >
                            In response to public emergencies such as earthquake, 
                            epidemic, military conflict and temporary resettlement, 
                            the residence can quickly build a fully functional emergency space to accommodate people.
                        </div>
                        <div className="product-box" >
                            <div className="product-left" >
                                <div className="product-h3" >
                                    Fast construction
                                </div>
                                <div className="product-text" >
                                    After the design and production is completed, 
                                    the products can be transported to the construction site as a whole or packaged. 
                                    The assembly is fast and the construction period is greatly shortened compared 
                                    with traditional buildings of the same size. The installation of a 3*6m frame only takes 15 minutes. 
                                    1620㎡  2000 unit, Mobile Cabin Hospital Project in Xi'an Branch Port, 3 days
                                </div>
                                <div className="product-h3" >
                                    Large simultaneous installation
                                </div>
                                <div className="product-text" >
                                    The site installation is convenient and only needs crane and workers. 
                                    It can be installed in a large area at the same time.
                                </div>
                                <div className="product-h3" >
                                    Strong mobility
                                </div>
                                <div className="product-text" >
                                    The modular structure has very strong 3D extensibility, 
                                    and can be increased or decreased depending on the site situation.
                                </div>
                            </div>
                            <div className="product-right" >
                                <img className="content-img" src="/product/10.png" />
                            </div>
                        </div>
                    </div>
                </div> : 
                typeFlag == 'facility' ? 
                <div>
                    <div className="bocsapce-introduce" >
                        <img className="content-img" src="/product/1.png" />
                    </div>
                    <div className="product-content" >
                        <div className="product-title" >
                            COMMERCIAL
                        </div>
                        <div className="product-h2" >
                            Functions can be customized for large-scale events and cultural exhibitions.  
                            The supporting unit includes but is not limited to viewing hall, lounge, catering, accommodation, 
                            makeup photography and other functions.  
                            The construction is fast and has a convenient turnover movement after the event. 
                        </div>
                        <div className="product-box" >
                            <div className="product-left" >
                                <div className="product-h3" >
                                    Fast construction, convenient turnover  
                                </div>
                                <div className="product-text" >
                                    After the completion of the design and production, 
                                    the products can be transported to the construction site as a whole or packaged. 
                                    The assembly is fast. Supporting facilities can be quickly built before the large-scale competition, 
                                    and part or all of them can be transferred after the competition 
                                    to avoid the vacancy and waste of resources.  
                                </div>
                                <div className="product-h3" >
                                    Wonderful experience  
                                </div>
                                <div className="product-text" >
                                    Complete supporting functions, integrated water and electricity, 
                                    complete furniture. Intelligent furniture can be configured according to demand.  
                                </div>
                                <div className="product-h3" >
                                    Flexiblility 
                                </div>
                                <div className="product-text" >
                                    Modular building, can be freely arranged according to the site conditions. 
                                </div>
                            </div>
                            <div className="product-right" >
                                <img className="content-img" src="/product/11.png" />
                            </div>
                        </div>
                    </div>
                </div> : 
                null
            }
        </Layout>
    )
}

export default ProductServicePage